@import "~bootstrap/scss/bootstrap-grid.scss";
@import "~bootstrap/scss/helpers/_stacks.scss";
@import "~@blueprintjs/core/lib/scss/variables";
@import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css";
@import "~@blueprintjs/popover2/lib/css/blueprint-popover2.css";
@import "~@blueprintjs/table/lib/css/table.css";

/*
* Colors - Light Mode
*/
$explorer-bg: #ebf1f5;
$explorer-visualized-bg: rgba(138, 155, 168, 0.15);
$explorer-visualized-color: rgb(24, 32, 38);
$explorer-header-bar: #fff;
$explorer-vis-config-panel-border: #b6c6d4; 
$explorer-vis-config-panel-color: #455a69;
$explorer-vis-config-panel-background: rgba(217, 229, 238, 0.3);
$explorer-search-query-usage: #cad9e4;
$explorer-querybook-scrollbar-track: #eee;
$explorer-querybook-scrollbar-handle: #888;
$explorer-querybook-scrollbar-hover: #555;

/*
* Colors - Dark Mode Overrides (.bp4-dark)
*/
$explorer-bg-dark: #171a1c;
$explorer-visualized-bg-dark: #b2d2eb;
$explorer-visualized-color-dark: #353c40;
$explorer-header-bar-dark: #171a1c;
$explorer-vis-config-panel-border-dark: rgb(48, 64, 77);
$explorer-vis-config-panel-color-dark: #f5f8fa;
$explorer-vis-config-panel-background-dark: rgba(138, 155, 168, 0.3);
$explorer-search-query-usage-dark: #58636c;
$explorer-querybook-scrollbar-track-dark: #30404d;
$explorer-querybook-scrollbar-handle-dark: #888;
$explorer-querybook-scrollbar-hover-dark: #555;

body, html, #root {
  height: calc(100vh - 50px)
}

body .body-wrapper {
  margin-top: calc($pt-navbar-height);
  padding-top: 12px;
  background: $explorer-bg;
  min-height: 100%; 
  height: auto;
}

body .bp4-dark.body-wrapper {
  background: $explorer-bg-dark
}

.body-wrapper .bp4-navbar.bp4-fixed-top {
  position: absolute
}
.full-width {
  width: 100%
}

.float-right {
  float: right
}

.top-margin-10 {
  margin-top: 10px;
}

.bp4-callout.visualized {
  background-color: $explorer-visualized-bg;
  color: $explorer-visualized-color;
}


.bp4-dark .bp4-callout.visualized {
  background-color: $explorer-visualized-bg-dark;
  color: $explorer-visualized-color-dark;
}


.vis-panel {
  padding-right: 0px;
}

.vis-conf .bp4-drawer {
  background-color: $explorer-bg;
}

.bp4-dark.vis-conf .bp4-drawer {
  background-color: $explorer-bg-dark;
}

.vis-conf .bp4-drawer-header {
  background-color: $explorer-header-bar 
}

.bp4-dark.vis-conf .bp4-drawer-header {
  background-color: $explorer-header-bar-dark 
}



.vis-conf .bp4-drawer .bp4-card {
  margin-top: 12px;
}
.bp4-drawer {
overflow-y: scroll
}
.series-config-color.react-colorful {
  height: 180px;
  width: 150px;

}

.bp4-dark .bp4-collapse.bp4-active .bp4-collapse-body, .bp4-dark .axis-config, .bp4-dark .series-tree-selector {
    border: 1px solid $explorer-vis-config-panel-border-dark;
    color: $explorer-vis-config-panel-color-dark;
    background: $explorer-vis-config-panel-background-dark;
}
.bp4-collapse.bp4-active .bp4-collapse-body, .axis-config, .series-tree-selector {
    border: 1px solid $explorer-vis-config-panel-border;
    color: $explorer-vis-config-panel-color;
    background: $explorer-vis-config-panel-background;
}
.bp4-collapse.bp4-active .bp4-collapse-body, .axis-config, .series-tree-selector {
    border-radius: 3px;
    padding: 10px 0 10px 0;
}

.axis-config .row {
  padding: 0 15px 0 15px;
}

.series-tree-selector {
  height: 300px;
  overflow-x: scroll
}

.axis-config {
  margin-bottom: 5px
}
.data-download-options {
  padding-top: 15px;

}

label.bp4-label.label-v-adjacent {
  margin-bottom: 5px
}

label.bp4-label.label-v-adjacent + div {
  margin-bottom: 15px
}

.bp4-dark  .bp4-card.squ-display {
  background-color: $explorer-search-query-usage-dark
}
.bp4-card.squ-display {
  margin-bottom: 8px;
  padding: 5px;
  background-color: $explorer-search-query-usage
}
.row {
  margin-top:calc(0.125 * var(--bs-gutter-y))
}

.row.query-book-save-row {
  margin-top: 1em;
}

.query-book-queries-list {
  max-height: 500px;
  overflow-y: scroll;
  
}

/* width */
.query-book-queries-list::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.bp4-dark .query-book-queries-list::-webkit-scrollbar-track {
  background: $explorer-querybook-scrollbar-track-dark; 
}
 
/* Handle */
.bp4-dark .query-book-queries-list::-webkit-scrollbar-thumb {
  background: $explorer-querybook-scrollbar-handle-dark; 
}

/* Handle on hover */
.bp4-dark .query-book-queries-list::-webkit-scrollbar-thumb:hover {
  background: $explorer-querybook-scrollbar-hover-dark; 
}

/* Track */
.query-book-queries-list::-webkit-scrollbar-track {
  background: $explorer-querybook-scrollbar-track; 
}
 
/* Handle */
.query-book-queries-list::-webkit-scrollbar-thumb {
  background: $explorer-querybook-scrollbar-handle; 
}

/* Handle on hover */
.query-book-queries-list::-webkit-scrollbar-thumb:hover {
  background: $explorer-querybook-scrollbar-hover; 
}




.bp4-dialog .bp4-card.squ-display {
  padding: 5px;
  margin-bottom: 0px;
}

.query-book-remove {
  margin-top: 2px;
}

.bp4-dialog.query-book-dialog {
    width: 80%;
}
@media only screen and (max-width: 950px) {
  .bp4-dialog.query-book-dialog {
    width: 100%;

  }
}

/*
* Location Browser Widget
*/

// Colors - Light
.location-browser button:focus {
  border: 1px solid $explorer-search-query-usage
}

.bp4-card.location-browser {
  background: $explorer-vis-config-panel-background
}

.location-browser-path{
  background-color: $explorer-header-bar;
  border: 1px solid $explorer-vis-config-panel-border;
  border-top-left-radius: 3px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 3px;
;
}
/* Track */
.location-browser-path::-webkit-scrollbar-track {
  background: $explorer-querybook-scrollbar-track; 
}
 
/* Handle */
.location-browser-path::-webkit-scrollbar-thumb {
  background: $explorer-querybook-scrollbar-handle; 
}

/* Handle on hover */
.location-browser-path::-webkit-scrollbar-thumb:hover {
  background: $explorer-querybook-scrollbar-hover; 
}




// Colors - Dark
.bp4-dark {

  .location-browser button:focus {
    border: 1px solid $explorer-search-query-usage-dark
  }

  .bp4-card.location-browser {
    background: $explorer-vis-config-panel-background-dark
  }

  .location-browser-path{
    background-color: $explorer-header-bar-dark 
  }

  /* Track */
  .location-browser-path::-webkit-scrollbar-track {
    background: $explorer-querybook-scrollbar-track-dark; 
  }
   
  /* Handle */
  .location-browser-path::-webkit-scrollbar-thumb {
    background: $explorer-querybook-scrollbar-handle-dark; 
  }

  /* Handle on hover */
  .location-browser-path::-webkit-scrollbar-thumb:hover {
    background: $explorer-querybook-scrollbar-hover-dark; 
  }


}

// symlnk icon
.location-button .bp4-icon-nest {
    transform: scaleY(-1);
    /* position: relative; */
    /* top: -0.4em; */
    margin-right: 0.2em;
    opacity: 0.4;
}

// Sizes

/* width */
.location-browser-path::-webkit-scrollbar {
  width: 10px;
}



.bp4-card.location-browser {
  height: 500px;
  display: flex;
  flex-direction: column;
}

.bp4-card.location-browser .row {
  flex-grow: 1
}

.location-browser-path .bp4-button-group.bp4-vertical {
  display: flex;
}

.location-browser-path {
  overflow-y: scroll;
  height: 100%;
  max-height: 450px;
}

.location-browser .col-sm-3 {
  padding: 5px
}

.location-browser button {
  border-radius: 0;
}

.location-browser button:focus {
  outline: none;
}

.location-item {
  flex: 1 0;
}

.location-item .bp4-checkbox {
  display: inline;
  z-index: 100;
  left: 6px;
}

.location-item .bp4-button.bp4-minimal {
  margin-left: -26px;
  padding-left: 29px;
  width: 100%
}

.location-browser .col-sm-3.hidden {
  display: none
}

.location-container .selected-path-list-item {
  display: none
}


.bp4-card.selection-viewer {
  background: $explorer-vis-config-panel-background
}

// Colors - Dark
.bp4-dark {
  .bp4-card.selection-viewer {
    background: $explorer-vis-config-panel-background-dark
  }
}

.selection-viewer {
  margin-top: 2em;
}

.article-selection-table .bp4-table-quadrant-scroll-container {
  max-height: 600px;
}

.bp4-tabs.vis-tabs .bp4-tab-list {
  flex: 0 0 auto;
  width: 8.33333333%;

}

.bp4-tabs.vis-tabs .bp4-tab-indicator-wrapper {
  max-width: 100%
}
.tab-name-truncate {
      overflow: hidden;
    text-overflow: ellipsis;
}


.dashboard-panel {
  margin-bottom: 10px;
  margin-top: 10px;
}

.h-16 {
  height: 16px
}

.w-16 {
  width: 16px
}

.talisman-embed {
  height: calc(100vh - 50px);
  width: calc(100% + var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
  border: 0px;
  margin-top: -10px;

}

.module-choice {
  height: 10em;
  width: 10em
  
}

